import React from "react";
import { Player } from "src/components/player";

import styles from "./three-up-video.module.css";

export const ThreeUpVideo = ({ media, isPlaying = false }) => {
  return (
    <div className={styles.root}>
      {media.threeUpVideo.map((item, index) => (
        <Player
          className={styles.player}
          key={item._key}
          posterImageId={item.posterImage.asset._id}
          url={item.video.url}
          aspectRatio={item.video.metadata?.aspectRatio || 0.57}
          enableCustomAspectRatio={true}
          isPlaying={index === 0 && isPlaying}
        />
      ))}
    </div>
  );
};
