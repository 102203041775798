import React, { useState, useLayoutEffect, useEffect } from "react";
import cx from "classnames";

export const RevealOnMount = ({ location, children }) => {
  const [shouldFadeIn, setShouldFadeIn] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useLayoutEffect(() => {
    // only fade in on push state transitions
    // not when using browser back/forward buttons
    if (
      location &&
      location.action === "PUSH" &&
      location.state.shouldUpdateScroll != false
    ) {
      setShouldFadeIn(true);
    }
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div
      className={cx(
        shouldFadeIn ? "fade-in-on-mount" : "",
        isMounted ? "is-mounted" : ""
      )}
    >
      {children}
    </div>
  );
};
