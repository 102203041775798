import React from "react";
import Helmet from "react-helmet";

const siteRoute = "https://youtoocanwoo.com";

export const SEO = ({ path, slug, metaInfo }) => {
  const title =
    metaInfo && metaInfo.metaTitle ? metaInfo.metaTitle : "YouTooCanWoo";
  const metaDescription =
    metaInfo && metaInfo.metaDescription
      ? metaInfo.metaDescription
      : "Artist-owned, and in the heart of Brooklyn, we’re your partner in music and sound.";
  const metaKeywords = "";
  const ogImage = metaInfo
    ? metaInfo.openImage
      ? metaInfo.openImage.asset.url
      : ""
    : "";
  const twitterImage = metaInfo
    ? metaInfo.twitterImage
      ? metaInfo.twitterImage.asset.url
      : metaInfo.openImage
      ? metaInfo.openImage.asset.url
      : ""
    : "";
  const openTitle = metaInfo
    ? metaInfo.openTitle
      ? metaInfo.openTitle
      : title
    : title;
  const openGraphDescription = metaInfo
    ? metaInfo.openGraphDescription
      ? metaInfo.openGraphDescription
      : metaDescription
    : metaDescription;
  const twitterTitle = metaInfo
    ? metaInfo.twitterTitle
      ? metaInfo.twitterTitle
      : title
    : title;
  const twitterDescription = metaInfo
    ? metaInfo.twitterDescription
      ? metaInfo.twitterDescription
      : metaDescription
    : metaDescription;
  return (
    <Helmet
      titleTemplate={`%s — YouTooCanWoo`}
      defaultTitle="YouTooCanWoo"
      title={title}
      bodyAttributes={{
        class: slug,
      }}
    >
      <html lang="en" />
      <meta http-equiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta property="og:locale" content="en_US" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <link rel="canonical" href={`${siteRoute}${path ? path : ""}`} />
      <meta property="og:url" content={`${siteRoute}${path ? path : ""}`} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={`${openTitle}`} />
      <meta property="og:site_name" content="YouTooCanWoo" />
      <meta property="og:description" content={openGraphDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${twitterTitle}`} />
      <meta name="twitter:image:src" content={twitterImage} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:url" content={`${siteRoute}${path ? path : ""}`} />
    </Helmet>
  );
};
