import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import cx from "classnames";
import { Image } from "src/components/image";
import {
  useEnableCustomCursor,
  useSetCustomCursorType,
} from "src/context/siteContext";
import { ReactComponent as Play } from "../../images/svg/Play.svg";
import { ReactComponent as Pause } from "../../images/svg/Pause.svg";
import { ReactComponent as VolumeOn } from "../../images/svg/VolumeOn.svg";
import { ReactComponent as VolumeOff } from "../../images/svg/VolumeOff.svg";
import { ReactComponent as FullScreen } from "../../images/svg/FullScreen.svg";

import * as styles from "./player.module.css";

export const Player = ({
  className,
  wrapperClassName,
  url,
  posterImageId,
  aspectRatio = 16 / 9,
  enableCustomAspectRatio = false,
  title = "",
  isPlaying = false,
}) => {
  const setCustomCursorType = useSetCustomCursorType();
  const enableCustomCursor = useEnableCustomCursor();

  const player = useRef();
  const controls = useRef();
  const [activeUrl, setActiveUrl] = useState(url);
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const isVideo = url && (url.includes("vimeo") || url.includes("youtube"));

  const handlePlay = () => {
    setPlaying(true);
  };

  const handleStop = () => {
    setPlaying(false);
    setActiveUrl(undefined);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setProgress(state.played);
    }
  };

  const handleEnded = () => {
    setPlaying(false);
    setActiveUrl(undefined);
    setActiveUrl(url);
    setProgress(0);

    // exit full screen
    if (screenfull.isEnabled && screenfull.isFullscreen) {
      screenfull.exit();
    }
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = e => {
    setProgress(e.target.value);
  };

  const handleSeekMouseUp = e => {
    setSeeking(false);
    player &&
      player.current &&
      player.current.seekTo(parseFloat(e.currentTarget.value));
  };

  const handleMutedToggle = () => {
    setMuted(!muted);
  };

  const handleClickFullscreen = () => {
    if (player?.current && screenfull.isEnabled) {
      screenfull.request(player?.current?.wrapper);
    }
  };

  const onMouseEnter = () => {
    setIsHovering(true);
    if (!playing) {
      enableCustomCursor(true);
      setCustomCursorType("play");
    }
  };

  const onMouseLeave = () => {
    setIsHovering(false);
    enableCustomCursor(false);
  };

  useEffect(() => {
    // setCustomCursorType(playing ? "pause" : "play");
    if (playing) {
      enableCustomCursor(false);
    } else if (isHovering) {
      enableCustomCursor(true);
      setCustomCursorType("play");
    }
  }, [playing]);

  useEffect(() => {
    setPlaying(isPlaying);
  }, [isPlaying]);

  return (
    <div
      className={cx(
        "x",
        styles.root,
        isVideo ? styles.isVideo : "",
        playing ? styles.isPlaying : "",
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        "--padding-bottom":
          enableCustomAspectRatio && aspectRatio
            ? `${(1 / aspectRatio) * 100}%`
            : "56.25%",
      }}
    >
      <div className={cx(styles.wrapper, wrapperClassName)}>
        <ReactPlayer
          ref={player}
          className={styles.player}
          url={activeUrl}
          playing={playing}
          volume={volume}
          muted={muted}
          onProgress={handleProgress}
          onEnded={handleEnded}
          controls={false}
          width="100%"
          height="100%"
          config={{
            youtube: { playerVars: { origin: "https://www.youtube.com" } },
          }}
        />

        <Image
          className={cx(
            "x y",
            styles.posterImage,
            // hide when videos are playing
            isVideo && (playing || progress !== 0) ? styles.hidden : ""
          )}
          imageId={posterImageId}
          style={{
            height: "100%",
          }}
        />
        <button
          className={cx("reset", styles.playPauseOverlay)}
          onClick={playing ? handlePause : handlePlay}
          aria-label={playing ? "Click to Pause" : "Click to Play"}
        />
      </div>
      <div ref={controls} className={styles.controls}>
        <button
          className={cx("reset", styles.playPause)}
          onClick={playing ? handlePause : handlePlay}
          aria-label="Play"
        >
          {playing ? <Pause /> : <Play />}
        </button>
        <input
          className={styles.progress}
          type="range"
          min={0}
          max={0.999999}
          step="any"
          value={progress}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          style={{ "--progress": `${progress * 100}%` }}
        />
        <button
          className={cx("reset", styles.volumeToggle)}
          onClick={handleMutedToggle}
          aria-label={muted ? "Unmute" : "Mute"}
        >
          {muted ? <VolumeOff /> : <VolumeOn />}
        </button>
        <button
          className={cx("reset", styles.fullscreenToggle)}
          onClick={handleClickFullscreen}
          aria-label="Fullscreen"
        >
          <FullScreen />
        </button>
      </div>
    </div>
  );
};
